@mixin head() {
  background-color: transparent;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}

nav {
  padding: 1rem;
}

#heading {
  display: flex;
  position: relative;
  justify-content: center;
  height: 1.5rem;
  z-index: 1001;
  button {
    @include head();
    border: none;
  }
}

#hamburger {
  left: 0;
  color: $yellow;
}

#app-prompt {
  right: 0;
  color: $blueColor;
  font-size: 1rem;
}

#title {
  @include head();
  img {
    height: 1.8rem;
  }
}

@mixin pow($primaryColor, $shadowColor) {
  font-family: Arimo;
  font-size: 2rem;
  color: $primaryColor;
  letter-spacing: 1rem;
  font-weight: 700;
  text-shadow: -0.1px 0.1px $shadowColor, -0.2px 0.2px $shadowColor,
    -0.3px 0.3px $shadowColor, -0.4px 0.4px $shadowColor,
    -0.5px 0.5px $shadowColor, -0.6px 0.6px $shadowColor,
    -0.7px 0.7px $shadowColor, -0.8px 0.8px $shadowColor,
    -0.9px 0.9px $shadowColor, -1px 1px $shadowColor, -1.1px 1.1px $shadowColor,
    -1.2px 1.2px $shadowColor, -1.3px 1.3px $shadowColor,
    -1.4px 1.4px $shadowColor, -1.5px 1.5px $shadowColor,
    -1.6px 1.6px $shadowColor;
}

#M {
  @include pow($pink, $yellow);
}
#I {
  @include pow($orange, #2a4036);
}
#X {
  @include pow($yellow, $red);
}
#E {
  @include pow(#626d53, #da7119);
}
#R {
  @include pow(#333448, #626d53);
}

#mixer-logo {
  margin: 1rem;
  h1 {
    text-align: center;
    font-family: monospace !important;
    color: $pink;
    font-size: 1.25rem !important;
    letter-spacing: -0.35rem;
    line-height: 0.5em;
  }
  h2 {
    text-align: center;
    font-family: monospace !important;
    font-size: 1.25rem !important;
    color: $yellow;
    letter-spacing: -0.35rem;
    line-height: 0.4rem;
  }
  img {
    height: 3rem !important;
  }
}
