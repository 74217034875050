.pop-up-menu {
  z-index: 1000;
  position: absolute;
  top: 0;
  background-color: $off-white;
  height: 100vh;
  width: 100%;

  &-hamburger {
    @extend .pop-up-menu;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 6rem;
      background-color: $yellow;
      border: 1px solid #707070cc;
      text-transform: uppercase;
      padding: 0.4rem;
      font-size: 1.2rem;
    }
  }

  color: $blueColor;
  font-size: 1rem;

  ::placeholder {
    text-overflow: ellipsis;
    color: $translucent-blue;
    font-size: 1rem;
  }
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.advance {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

form {
  > .large {
    margin-top: 1.5rem;
  }
  > .prompt {
    margin-top: 1.5rem;
  }
}

.large {
  max-width: 28rem;
  width: 80vw;
  border: 2px $blueColor solid;
  height: 3rem;

  input {
    height: 100%;
    width: 100%;
    border: none;
    font-size: 1rem;
    color: $pink;
  }

  --PhoneInputCountryFlag-height: 1rem;
  --PhoneInputCountryFlag-borderWidth: 0;
  --PhoneInput-color--focus: $pink;

  .PhoneInput {
    padding-left: 0.5rem;
    height: 100%;
    width: 100%;
  }

  &-button {
    @extend .large;
    margin-top: 1rem;
    font-size: 1.3rem;

    &-dark {
      @extend .large-button;
      background-color: $blueColor;
      color: $off-white;
    }
  }
}

.large > input {
  padding-left: 0.5rem;
}

.advance {
  position: absolute;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: -1;
  .large {
    max-width: 28rem;
    width: calc(100vw - 30px);
    height: 2.5rem;
  }
}

input:focus {
  outline: none;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  position: relative;
  color: $blueColor;
}
#post-prompt {
  display: flex;
  flex-direction: column;

  padding: 6rem 1rem;
  img {
    height: 2rem;
  }
}

.prompt {
  text-align: center;
  font-weight: normal;
  h1 {
    font-weight: normal;
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1rem;
    font-weight: normal;
  }
}

input:focus::placeholder,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0;
  -webkit-box-shadow: 0 0 0 30px $off-white inset !important;
  -webkit-text-fill-color: $pink;
}
