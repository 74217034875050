$blueColor: #333448;
$translucent-blue: rgba(51, 52, 72, 0.5);
$yellow: #d89a24;
$pink: #d8b3c7;
$translucent-pink: rgba(216, 179, 199, 0.7);
$red: #cf2e18;
$orange: #da4624;
// $off-white: #f9f9f9;
$off-white: white;

button {
  &.blue {
    color: $blueColor !important;
  }
}
