html {
  font-size: 16px;
}

@import "variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $off-white;
  font-family: Arial, Helvetica, sans-serif;
}

@import "header";
@import "menu";
@import "event";

.content {
  max-width: 30rem;
  margin: auto;
}

.error {
  color: "#CF2E18";
  padding: "0px 1.56rem";
  font-size: "1rem";
}
.carouselHello {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 40vmin;
  }
}

ul.control-dots {
  display: none;
}
.carousel-root {
  display: flex;
  justify-content: center;
  max-width: 100vw;
}

.carousel {
  outline: 2px solid black;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 1rem 0;
}

.homeImages > h1 {
  color: red;
  display: flex;
  flex-direction: column;
}

.zero {
  color: $blueColor;
}

.one {
  color: $blueColor;
}

.two {
  color: $pink;
}
