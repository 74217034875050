.box {
  border-radius: 1px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  font-weight: normal;
}

#eventImgArea {
  width: 100%;
  position: relative;
  padding-top: 120%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

#rsvpForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-evenly;
  text-align: center;
  color: $blueColor;
  margin-top: 1rem;
  margin-bottom: 1rem;

  label {
    border: 0.4px solid rgba($color: #000000, $alpha: 0.5);
    margin-left: -1px;
    margin-right: -1px;
    padding: 0.2rem 0;
  }
}

.eventDetails {
  display: flex;
  flex-direction: column;

  h2 {
    color: $blueColor;
    font-size: 0.9rem;
    font-weight: normal;
    line-height: 1.2rem;
  }
}
#eventName {
  font-size: 1.8rem;
  font-weight: normal;
  color: $blueColor;
  line-height: 2rem;
}

input[type="radio"] {
  position: fixed;
  width: 0;
}

#details {
  padding-top: 1rem;
  border-top: 0.4px solid rgba($color: #000000, $alpha: 0.5);
}
